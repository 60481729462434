export const data = [
    {"市级":"济南市","业主单位":"","计划书批复号":"文物保函﹝2019﹞1094号","不可移动文物名称":"灵岩寺","计划书名称":"灵岩寺千佛殿屋顶保护修缮","计划书日期":"2019年8月","进展状态":"前期准备","方案批复号":"鲁文旅许﹝2021﹞871号","技术方案名称":"山东长清灵岩寺千佛殿屋顶保护修缮工程","方案编制单位":"北京建工建筑设计研究院","方案编制日期":"","累计安排（万元）":"0","资金项目编号":"21-1-02-3700-465","监理单位":"","施工单位":"","采购方式":"","工程进展情况":"","更新日期":""},
    {"市级":"济南市","业主单位":"","计划书批复号":"文物保函﹝2019﹞1094号","不可移动文物名称":"万字会旧址","计划书名称":"万字会旧址济南母院一进、二进建筑保护修缮工程","计划书日期":"2019年8月","进展状态":"前期准备","方案批复号":"鲁文旅许﹝2021﹞908号","技术方案名称":"万字会旧址济南母院一进、二进建筑保护修缮工程","方案编制单位":"山东省古建筑保护研究院","方案编制日期":"","累计安排（万元）":"0","资金项目编号":"21-1-02-3700-451","监理单位":"","施工单位":"","采购方式":"","工程进展情况":"","更新日期":""},
    {"市级":"聊城市","业主单位":"","计划书批复号":"文物保函﹝2019﹞1094号","不可移动文物名称":"聊城山陕会馆","计划书名称":"聊城山陕会馆山门、戏楼、中献殿、南献殿、火神殿、北献殿、财神殿、春秋阁及南北望楼十一处建筑彩画修缮前期勘察研究","计划书日期":"2019年8月","进展状态":"工程进行中","方案批复号":"鲁文旅保﹝2020﹞66号","技术方案名称":"聊城山陕会馆十一处建筑彩画修缮前期勘测","方案编制单位":"","方案编制日期":"","累计安排（万元）":"148","资金项目编号":"20-1-02-3700-234","监理单位":"","施工单位":"","采购方式":"","工程进展情况":"","更新日期":""},
    {"市级":"济南市","业主单位":"","计划书批复号":"文物保函﹝2019﹞1094号","不可移动文物名称":"洪家楼天主教堂","计划书名称":"济南洪家楼天主教堂屋面修缮保护修缮","计划书日期":"2019年8月","进展状态":"工程进行中","方案批复号":"鲁文旅保﹝2020﹞69号","技术方案名称":"济南洪家楼天主教堂屋面修缮保护","方案编制单位":"","方案编制日期":"","累计安排（万元）":"420","资金项目编号":"20-1-02-3700-233","监理单位":"","施工单位":"","采购方式":"","工程进展情况":"","更新日期":""},
    {"市级":"直属","业主单位":"","计划书批复号":"文物保函﹝2019﹞1094号","不可移动文物名称":"原齐鲁大学近现代建筑群","计划书名称":"原齐鲁大学近现代建筑群-青杨路5#楼保护修缮","计划书日期":"2019年8月","进展状态":"工程进行中","方案批复号":"鲁文旅保﹝2020﹞67号","技术方案名称":"原齐鲁大学近现代建筑群-青杨路5#楼修缮保护工程","方案编制单位":"","方案编制日期":"","累计安排（万元）":"150","资金项目编号":"20-1-02-3700-230","监理单位":"","施工单位":"","采购方式":"","工程进展情况":"","更新日期":""},
    {"市级":"直属","业主单位":"","计划书批复号":"文物保函﹝2019﹞1094号","不可移动文物名称":"原齐鲁大学近现代建筑群","计划书名称":"原齐鲁大学近现代建筑群——桐荫阁、教授别墅-青杨路9号保护修缮","计划书日期":"2019年8月","进展状态":"工程进行中","方案批复号":"鲁文旅保﹝2020﹞63号","技术方案名称":"原齐鲁大学近现代建筑群——桐荫阁、教授别墅-青杨路9号修缮保护工程","方案编制单位":"","方案编制日期":"","累计安排（万元）":"170","资金项目编号":"20-1-02-3700-229","监理单位":"","施工单位":"","采购方式":"","工程进展情况":"","更新日期":""},
    {"市级":"直属","业主单位":"","计划书批复号":"文物保函﹝2019﹞1094号","不可移动文物名称":"南武城故城遗址","计划书名称":"南武城故城遗址北墙西段、西墙保护修缮","计划书日期":"2019年8月","进展状态":"工程进行中","方案批复号":"鲁文旅保﹝2020﹞34号","技术方案名称":"南武城故城遗址北墙西段、西墙保护修缮","方案编制单位":"","方案编制日期":"","累计安排（万元）":"180","资金项目编号":"20-1-02-3700-197","监理单位":"","施工单位":"","采购方式":"","工程进展情况":"","更新日期":""},
    {"市级":"直属","业主单位":"","计划书批复号":"文物保函﹝2019﹞1094号","不可移动文物名称":"青堌堆遗址","计划书名称":"梁山县青堌堆遗址考古勘探及报告编制","计划书日期":"2019年8月","进展状态":"工程进行中","方案批复号":"鲁文旅许﹝2020﹞304号","技术方案名称":"梁山县青堌堆遗址考古勘探及报告编制","方案编制单位":"","方案编制日期":"","累计安排（万元）":"100","资金项目编号":"20-1-02-3700-063","监理单位":"","施工单位":"","采购方式":"","工程进展情况":"","更新日期":""},
    {"市级":"济宁市","业主单位":"","计划书批复号":"文物保函﹝2019﹞1094号","不可移动文物名称":"嘉祥武氏墓群石刻","计划书名称":"嘉祥武氏墓群石刻前期勘察研究","计划书日期":"2019年8月","进展状态":"工程进行中","方案批复号":"鲁文旅保﹝2020﹞35号","技术方案名称":"嘉祥武氏墓群石刻前期勘察研究","方案编制单位":"","方案编制日期":"","累计安排（万元）":"200","资金项目编号":"20-1-02-3700-028","监理单位":"","施工单位":"","采购方式":"","工程进展情况":"","更新日期":""},
    {"市级":"泰安市","业主单位":"","计划书批复号":"文物保函﹝2019﹞1094号","不可移动文物名称":"泰山古建筑群","计划书名称":"泰山古建筑群——关帝庙保护修缮","计划书日期":"2019年8月","进展状态":"前期准备","方案批复号":"","技术方案名称":"泰山古建筑群——关帝庙保护修缮工程","方案编制单位":"北京建工建筑设计研究院","方案编制日期":"","累计安排（万元）":"0","资金项目编号":"1-02-21-3700-022","监理单位":"","施工单位":"","采购方式":"","工程进展情况":"","更新日期":""},
    {"市级":"淄博市","业主单位":"","计划书批复号":"文物保函﹝2019﹞1094号","不可移动文物名称":"临淄齐国故城","计划书名称":"临淄齐国故城——大城东城墙北段保护展示","计划书日期":"2019年8月","进展状态":"未编制","方案批复号":"","技术方案名称":"","方案编制单位":"","方案编制日期":"","累计安排（万元）":"","资金项目编号":"","监理单位":"","施工单位":"","采购方式":"","工程进展情况":"","更新日期":""},
    {"市级":"淄博市","业主单位":"","计划书批复号":"文物保函﹝2019﹞1094号","不可移动文物名称":"临淄齐国故城","计划书名称":"临淄齐国故城——晏婴冢保护展示","计划书日期":"2019年8月","进展状态":"技术方案编制中","方案批复号":"","技术方案名称":"","方案编制单位":"","方案编制日期":"","累计安排（万元）":"","资金项目编号":"","监理单位":"","施工单位":"","采购方式":"","工程进展情况":"","更新日期":""},
    {"市级":"威海市","业主单位":"","计划书批复号":"文物保函﹝2019﹞1094号","不可移动文物名称":"留村石墓群","计划书名称":"留村石墓群石质文物本体保护前期勘察研究 ","计划书日期":"2019年8月","进展状态":"方案审批中","方案批复号":"","技术方案名称":"","方案编制单位":"","方案编制日期":"","累计安排（万元）":"","资金项目编号":"","监理单位":"","施工单位":"","采购方式":"","工程进展情况":"","更新日期":""},
    {"市级":"济宁市","业主单位":"","计划书批复号":"文物保函﹝2019﹞1094号","不可移动文物名称":"曾庙","计划书名称":"曾庙院落地面及排水保护修缮","计划书日期":"2019年8月","进展状态":"获方案批复","方案批复号":"","技术方案名称":"","方案编制单位":"","方案编制日期":"","累计安排（万元）":"","资金项目编号":"","监理单位":"","施工单位":"","采购方式":"","工程进展情况":"","更新日期":""},
    {"市级":"济宁市","业主单位":"","计划书批复号":"文物保函﹝2019﹞1094号","不可移动文物名称":"曲阜孔庙及孔府","计划书名称":"曲阜孔庙后土祠、神厨、神庖建筑组群保护修缮","计划书日期":"2019年8月","进展状态":"前期准备","方案批复号":"","技术方案名称":"","方案编制单位":"","方案编制日期":"","累计安排（万元）":"","资金项目编号":"","监理单位":"","施工单位":"","采购方式":"","工程进展情况":"","更新日期":""},
    
    ]

    export const cityDic = [...new Set(data.map((p) => p.市级))];